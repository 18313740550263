<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <div class="filter d-flex align-center justify-center px-10">
      <div class="d-flex align-center mr-8">
        <span class="mr-2">
          Empresas:
        </span>
        <v-autocomplete
          v-model="filters.companies"
          :items="companyItems"
          multiple
          placeholder="Todas las empresas"
          class="range-field"
        />
      </div>
    </div>
    <div class="filter d-flex align-center justify-center px-10">
      <div class="d-flex align-center mr-8">
        <span class="mr-2">
          RSI14:
        </span>
        <v-text-field
          v-model="filters.rsi14"
          class="mt-0 pt-0 range-field"
          hide-details
          single-line
          type="number"
          :min="0"
          style="width: 50px"
        />
      </div>
      <div class="d-flex align-center mr-8" v-if="filters.rsi14">
        <span class="mr-2">
          TOLE. RSI14:
        </span>
        <v-text-field
          v-model="filters.toleranciaRsi14"
          class="mt-0 pt-0 range-field"
          hide-details
          single-line
          type="number"
          :min="0"
          style="width: 50px"
        />
      </div>
      <!-- <div class="d-flex align-center mr-8">
          <span class="mr-2">
            SMA10:
          </span>
          <v-text-field
            v-model="filters.sma10"
            class="mt-0 pt-0 range-field"
            hide-details
            single-line
            type="number"
            :min="0"
            style="width: 50px"
          />
      </div>
      <div class="d-flex align-center mr-8">
          <span class="mr-2">
            SMA20:
          </span>
          <v-text-field
            v-model="filters.sma20"
            class="mt-0 pt-0 range-field"
            hide-details
            single-line
            type="number"
            :min="0"
            style="width: 50px"
          />
      </div>
      <div class="d-flex align-center mr-8">
          <span class="mr-2">
            SMA50:
          </span>
          <v-text-field
            v-model="filters.sma50"
            class="mt-0 pt-0 range-field"
            hide-details
            single-line
            type="number"
            :min="0"
            style="width: 50px"
          />
      </div>
      <div class="d-flex align-center mr-8">
          <span class="mr-2">
            SMA200:
          </span>
          <v-text-field
            v-model="filters.sma200"
            class="mt-0 pt-0 range-field"
            hide-details
            single-line
            type="number"
            :min="0"
            style="width: 50px"
          />
      </div> -->
      <div class="d-flex align-center mr-8">
        <span class="mr-2">
          M:
        </span>
        <v-text-field
          v-model="filters.macd"
          class="mt-0 pt-0 range-field"
          hide-details
          single-line
          type="number"
          :min="0"
          style="width: 50px"
        />
      </div>
      <div class="d-flex align-center mr-8" v-if="filters.macd">
        <span class="mr-2">
          TOLE. M:
        </span>
        <v-text-field
          v-model="filters.toleranciaMacd"
          class="mt-0 pt-0 range-field"
          hide-details
          single-line
          type="number"
          :min="0"
          style="width: 50px"
        />
      </div>
      <!-- <div class="d-flex align-center mr-8">
          <span class="mr-2">
            M.S:
          </span>
          <v-text-field
            v-model="filters.macdSignal"
            class="mt-0 pt-0 range-field"
            hide-details
            single-line
            type="number"
            :min="0"
            style="width: 50px"
          />
      </div> -->
      <div class="d-flex align-center mr-8">
        <span class="mr-2">
          M.H:
        </span>
        <v-text-field
          v-model="filters.macdHist"
          class="mt-0 pt-0 range-field"
          hide-details
          single-line
          type="number"
          :min="0"
          style="width: 50px"
        />
      </div>
      <div class="d-flex align-center mr-8" v-if="filters.macdHist">
        <span class="mr-2">
          TOLE. M.H:
        </span>
        <v-text-field
          v-model="filters.toleranciaMacdHist"
          class="mt-0 pt-0 range-field"
          hide-details
          single-line
          type="number"
          :min="0"
          style="width: 50px"
        />
      </div>
      <v-btn
        v-if="
          (filters.rsi14 && filters.toleranciaRsi14) ||
            (filters.macdHist && filters.toleranciaMacdHist) ||
            (filters.macd && filters.toleranciaMacd)
        "
        depressed
        color="primary"
        @click="applyFilter"
      >
        Filtrar
      </v-btn>
    </div>
    <material-card icon="mdi-clipboard-text" icon-small color="accent">
      <v-data-table
        :headers="table.headers"
        :item-class="classes"
        :items="resultData"
        :items-per-page="5000"
        disable-sort
        :footer-props="{
          'disable-items-per-page': true,
          'items-per-page-text': '',
        }"
      />
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </material-card>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import companies from '../companies'
  const RUTA_SERVIDOR = 'https://www.financetradebio.com'
  export default {
    name: 'RegularTablesView',
    data() {
      return {
        dialog: false,
        table: {
          headers: [
            { text: 'Symbol', value: 'ticker' },
            { text: 'Stock Price', value: 'stockPrice' },
            { text: 'RSI 14', value: 'rsi14' },
            { text: 'SMA 10', value: 'sma10' },
            { text: 'SMA 20', value: 'sma20' },
            { text: 'SMA 50', value: 'sma50' },
            { text: 'SMA 200', value: 'sma200' },
            { text: 'MACD', value: 'macd' },
            { text: 'MACD SIGNAL', value: 'macdSignal' },
            { text: 'MACD HIST', value: 'macdHist' },
          ],
        },
        filters: {
          companies: [],
          rsi14: null,
          macd: null,
          macdHist: null,
          // macdSignal: null,
          toleranciaRsi14: 0,
          toleranciaMacdHist: 0,
          toleranciaMacd: 0,
          // sma10: null,
          // sma20: null,
          // sma50: null,
          // sma200: null,
        },
        responseData: [],
        loading: false,
        companies,
        companyItems: [],
        data: [],
        tickerErrors: [],
        firstCalc: false,
      }
    },
    computed: {
      resultData() {
        if (this.filters.companies.length) {
          return this.data.filter(({ ticker }) => {
            return this.filters.companies.includes(ticker)
          })
        }
        return this.data
      },
    },
    async mounted() {
      // const data = await this.getData()
    },
    methods: {
      classes(e) {
        var result = ''
        if (e.par) {
          result = result += ' strike-column'
        }
        if (e.gn) {
          result = result += ' gn'
        }
        return result
      },
      async applyFilter() {
        const rsi14 = this.filters.rsi14
        const macd = this.filters.macd
        const macdHist = this.filters.macdHist
        const toleranciaRsi14 = this.filters.toleranciaRsi14
        const toleranciaMacdHist = this.filters.toleranciaMacdHist
        const toleranciaMacd = this.filters.toleranciaMacd
        // const macdSignal = this.filters.macdSignal;
        // const sma10 = this.filters.sma10;
        // const sma20 = this.filters.sma20;
        // const sma50 = this.filters.sma50;
        // const sma200 = this.filters.sma200;

        this.loading = true
        const url = `${RUTA_SERVIDOR}/smaRsi/all/query`
        try {
          const data = await axios
            .get(url, {
              params: {
                rsi14,
                macd,
                macdHist,
                toleranciaRsi14,
                toleranciaMacdHist,
                toleranciaMacd,
              },
            })
            .then(res => res.data.data)
          this.companyItems = data.reduce((total, current) => {
            if (current.ticker) {
              const isExist = total.some(x => x.value === current.ticker)
              if (!isExist) {
                total.push({ value: current.ticker, text: current.ticker })
              }
            }
            return total
          }, [])
          this.responseData = data
          this.data = data
        } catch (error) {
          console.log(error)
        }
        this.loading = false
      },
    },
    created() {
      const token = localStorage.getItem('token')
      if (!token) {
        window.location.href = '/login'
      }
    },
  }
</script>
<style lang="scss">
  .modal {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    align-items: center;
    span.tt {
      margin-right: 25px;
    }
  }
  .companies-error {
    ul {
      display: flex;
      list-style: none;
      flex-wrap: wrap;
      padding: 0;
      padding-top: 10px;
      li {
        padding-right: 10px;
        font-size: 18px;
        color: #000;
        font-weight: normal;
      }
    }
  }
  .range-field {
    flex: none;
    padding: 0;
    .v-text-field__details {
      display: none !important;
    }
  }
  .gn td {
    color: red !important;
  }
  .strike-column {
    background: #eaebec;
  }
  .v-data-footer__select .v-input {
    display: none !important;
  }
</style>
